import { Stack, Image } from "react-bootstrap";
import { StopType, Task, TaskType } from "../../tasks/types";
import iconLogo from "../../layout/icon-logo.png";
import moment from "moment-timezone";
import { UpdateType } from "@/components/task-updates/types";
import { getContactIcon } from "@/components/utils/contact-utils";
import { IconExternalLink, IconMail } from "@tabler/icons-react";
import { SchedulingMethod } from "@/components/scheduling-methods/type";
import { ContactType } from "@/components/contacts/type";
import { getShortDateTime } from "@/components/utils/date-time-utils";
import ContactUpdate from "./contact-update";
import { isPortalUpdate } from "@/components/utils/task-utils";

const TaskUpdates = ({ task } : { task: Task }) => {
  const updates = () => {
    let updates = [];
    let contacts = [...task.contacts];
    let lastTime = task.scheduledAt;
    let lastEarlyTime = task.earlyScheduledAt;
    let apptTime: string = null;
    let earlyApptTime: string = null;
    let plannedAt: string = null;
    let latePlannedAt: string = null;
    let lastSchedulingMethod = task.schedulingMethod;

    if (task.taskUpdates.length) {
      for (let i = task.taskUpdates.length - 1; i >= 0; i--) {
        const taskUpdate = task.taskUpdates[i];
        if (taskUpdate.updateType !== UpdateType.PLANNED_AT || taskUpdate.value1) {
          updates.push(<Stack direction="horizontal" gap={3} className="align-items-start mt-3">
            <div className="p-2">
              {taskUpdate.triggeredBy?.name === 'HubFlow' ?
              <Image src={iconLogo} title="HubFlow" width="32" /> :
              (taskUpdate.triggeredBy?.name === 'TMS' ? 
              <IconExternalLink size={32}><title>TMS</title></IconExternalLink> :
              (taskUpdate.triggeredBy ?
              <IconMail size={32}><title>{taskUpdate.triggeredBy.address}</title></IconMail> :
              <div className="cl-internal-11ewti4">
                <Image src="https://img.clerk.com/eyJ0eXBlIjoiZGVmYXVsdCIsImlpZCI6Imluc18yZUZtZGtjRkpJRzljZ3lKNmZnSHVWdkJMdUwiLCJyaWQiOiJ1c2VyXzJrV1pNekNuc1FoUjFxd0wwMG05U1BDSmVUWSIsImluaXRpYWxzIjoiQUEifQ?width=160"
                  title={taskUpdate.createdByName} width="100%" height="100%" />
              </div>))}
            </div>
            <Stack gap={1}>
              <Stack direction="horizontal" gap={3} className="justify-content-between align-items-start">
                <div className="fw-bold" title={taskUpdate.triggeredBy?.address}>
                  {taskUpdate.triggeredBy?.name?? taskUpdate.createdByName}
                </div>
                <div className="pe-3">{moment(taskUpdate.createdAt).fromNow()}</div>
              </Stack>
              {taskUpdate.updateType === UpdateType.CONTACTS ?
                <Stack direction="horizontal" gap={2}>Changed contacts: {taskUpdate.value1}
                  {isPortalUpdate(taskUpdate.value2) ?
                  <ContactUpdate taskUpdate={taskUpdate} /> : <span> {taskUpdate.value2}</span>}
                </Stack> :
                (taskUpdate.updateType === UpdateType.STATUS ?
                <Stack direction="horizontal" gap={2}>
                  Changed status: <del className="text-capitalize">{taskUpdate.value1.replaceAll('_', ' ')}</del>
                  <b className="text-capitalize">{taskUpdate.value2.replaceAll('_', ' ')}</b>
                </Stack> : ([UpdateType.MESSAGE, UpdateType.EMAIL].includes(taskUpdate.updateType) ?
                  <div dangerouslySetInnerHTML={{ __html: taskUpdate.value1 }}></div> : 
                  (taskUpdate.updateType === UpdateType.SYNCED ?
                  <div>Appointment time has been updated on TMS!</div> :
                  ([UpdateType.PLANNED_AT, UpdateType.LATE_PLANNED_AT].includes(taskUpdate.updateType) ?
                  <Stack direction="horizontal" gap={2}>
                    Changed TMS{taskUpdate.updateType === UpdateType.LATE_PLANNED_AT ? ' late' : ''} time:
                    <del>{taskUpdate.value1.includes('T') ?
                      moment(taskUpdate.value1).tz(task.timezone).format('MM/DD HH:mm') :
                      taskUpdate.value1.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*$/, '$2/$3 $4:$5')}</del>
                    <b>{taskUpdate.value2.includes('T') ?
                      moment(taskUpdate.value2).tz(task.timezone).format('MM/DD HH:mm') :
                      taskUpdate.value2.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*$/, '$2/$3 $4:$5')}</b>
                  </Stack>:
                  ([UpdateType.SCHEDULED_AT, UpdateType.EARLY_SCHEDULED_AT].includes(taskUpdate.updateType) ? 
                  <Stack direction="horizontal" gap={2}>
                    Changed{taskUpdate.updateType === UpdateType.EARLY_SCHEDULED_AT ? ' early' : ''} appointment time:
                    <del>{getShortDateTime(new Date(taskUpdate.value1), task.timezone)}</del>
                    <b>{getShortDateTime(new Date(taskUpdate.value2), task.timezone)}</b>
                  </Stack> :
                  (taskUpdate.updateType === UpdateType.BROWSER_SESSION ?
                  <Stack>
                    <a href={`/sessions/${taskUpdate.value1}`}>Browser session created</a>
                  </Stack>:
                  <Stack direction="horizontal" gap={2}>
                    Changed {taskUpdate.updateType === UpdateType.SCHEDULING_METHOD ?
                      'scheduling method' : 'confirmation number'}:
                    <del>{taskUpdate.value1}</del>
                    <b>{taskUpdate.value2}</b>
                  </Stack>))))))}
            </Stack>
          </Stack>);
        }
        switch(taskUpdate.updateType) {
          case UpdateType.PLANNED_AT:
            if (!taskUpdate.value1) {
              plannedAt = taskUpdate.value2;
            }
            break;
          case UpdateType.LATE_PLANNED_AT:
            if (!taskUpdate.value1) {
              latePlannedAt = taskUpdate.value2;
            }
            break;
          case UpdateType.SCHEDULED_AT:
            lastTime = new Date(taskUpdate.value1);
            break;
          case UpdateType.EARLY_SCHEDULED_AT:
            lastEarlyTime = new Date(taskUpdate.value1);
            break;
          case UpdateType.SCHEDULING_METHOD:
            lastSchedulingMethod = taskUpdate.value1 as SchedulingMethod;
            break
          case UpdateType.CONTACTS:
            const actions = taskUpdate.value1.split(' ');
            if (actions[0] == 'added') {
              contacts = contacts.filter(c => c.contact !== taskUpdate.value2);
            } else {
              contacts.push({ contact: taskUpdate.value2,
                contactType: actions[1] as ContactType, taskId: task.id });
            }
            break;
        }
      }
      if (task.taskType === TaskType.SCHEDULING) {
        apptTime = getShortDateTime(new Date(lastTime), task.timezone);
        if (lastEarlyTime) {
          earlyApptTime = getShortDateTime(new Date(lastEarlyTime), task.timezone);
        }
        plannedAt = plannedAt.includes('T') ?
          moment(plannedAt).tz(task.timezone).format('MM/DD HH:mm') :
          plannedAt.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*$/, '$2/$3 $4:$5');
        if (latePlannedAt) {
          latePlannedAt = latePlannedAt.includes('T') ?
            moment(latePlannedAt).tz(task.timezone).format('MM/DD HH:mm') :
            latePlannedAt.replace(/^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2}).*$/, '$2/$3 $4:$5');
        }
        updates.push(<Stack direction="horizontal" gap={3} className="align-items-start my-3">
          <div className="p-2">
              <Image src={iconLogo} title="HubFlow" width="32" />
          </div>
          <Stack gap={1}>
            <Stack direction="horizontal" gap={3} className="justify-content-between align-items-start">
              <div className="fw-bold">HubFlow</div>
              <div className="pe-3">{moment(task.createdAt).fromNow()}</div>
            </Stack>
            <Stack gap={2}>
              <span>
                Task created {task.stopType === StopType.DROP_OFF ? ' – dropoff scheduling required' : <></>}
              </span>
              {!earlyApptTime || earlyApptTime === apptTime ? <></> :
              <Stack direction="horizontal">
                <span>Early appointment time suggested:</span>
                <span className="ms-2">{earlyApptTime}</span>
              </Stack>}
              {apptTime === plannedAt ? <></> :
              <Stack direction="horizontal">
                <span>Appointment time suggested:</span>
                <span className="ms-2">{apptTime}</span>
              </Stack>}
              <Stack direction="horizontal">
                <span>TMS appointment time:</span>
                <span className="ms-2">{plannedAt}</span>
              </Stack>
              {!latePlannedAt ? <></> :
              <Stack direction="horizontal">
                <span>TMS late appointment time:</span>
                <span className="ms-2">{latePlannedAt}</span>
              </Stack>}
              <Stack direction="horizontal">
                <span>Scheduling method:</span>
                <span className="ms-2 text-capitalize">{lastSchedulingMethod}</span>
              </Stack>
              <Stack direction="horizontal" gap={2}>
                <span>Contact identified:</span>
                {contacts.map(contact =>
                  <div className="contact border rounded p-1 me-1 d-flex">
                    <i className={"me-1 " + getContactIcon(contact.contactType)} />
                    {contact.contact}
                  </div>)}
              </Stack>
            </Stack>
          </Stack>
        </Stack>);
      }
    }
    return updates;
  }

  return (
    <Stack>
      {updates()}
    </Stack>
  );
};

export default TaskUpdates;
