import useRailsToast from "@/components/utils/use-rails-toast";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown, Form, InputGroup, Modal, Stack } from "react-bootstrap";
import { convertBodyToHtml, generateMessage, generateSubject } from "@/components/utils/task-utils";
import { Task, TaskStatus } from "@/components/tasks/types";
import { HumanizeType } from "@/components/tasks/show/humanize-types";
import { getStatusClass } from "@/components/tasks/show/task-status-pill";
import { ContactType } from "@/components/contacts/type";
import { acceptableContactType } from "@/components/utils/contact-utils";
import TaskContactsInput from "@/components/tasks/show/contacts/task_contacts_input";
import { useNavigate } from "react-router-dom";

const CreateTaskUpdate = ({ task, onUpdate } :
  { task: Task, onUpdate: (isCompleted: boolean) => void }) => {
  const { getToken, orgRole, orgId } = useAuth();
  const railsToast = useRailsToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [newStatus, setNewStatus] = useState<TaskStatus>(TaskStatus.IN_PROGRESS);
  const [sendEmail, setSendEmail] = useState<boolean>(false);
  const [updateMessage, setUpdateMessage] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [showUpdate, setShowUpdate] = useState<boolean>(false);

  useEffect(() => {
    switch (task.status) {
      case TaskStatus.SUGGESTED:
        if (acceptableContactType(task.schedulingMethod) === ContactType.EMAIL) {
          setSendEmail(task.contacts.length > 0 && orgRole !== 'org:agent');
          setSubject(generateSubject(task, orgId));
          setUpdateMessage(generateMessage(task, orgId));
        }
        break;
      case TaskStatus.AWAITING_SYNC:
        setNewStatus(TaskStatus.COMPLETED);
        break;
      default:
        setNewStatus(task.status);
    }
  }, []);

  const updateTaskStatus = async () => {
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { status: newStatus } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      railsToast({ info: "Task status has been updated!" }, status);
      const statusUpdated = newStatus !== task.status;
      task.status = newStatus;
      task.taskUpdates.push(...json);
      onUpdate(statusUpdated && task.status === TaskStatus.COMPLETED);
    } else {
      railsToast(json, status);
    }
  };

  const checkContacts = () => {
    if ((acceptableContactType(task.facility?.facilityNotes?.[0]?.schedulingMethod) === ContactType.EMAIL &&
      orgRole !== 'org:agent' && task.contacts.every(c => task.facility.facilityNotes?.[0]?.
      contacts?.some(fc => fc.contact === c.contact))) || !sendEmail) {
      onSubmit(false);
    } else {
      setShowUpdate(true);
    }
  };

  const startSession = async () => {
    setLoading(true);
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/sessions?task_id=${task.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const data = await response.json();
    if (response.ok) {
      navigate(`/sessions/${data.sessionId}`);
    } else {
      railsToast(data, response.status);
    }
    setLoading(false);
  };

  const onSubmit = async (updateFacility: boolean) => {
    setShowUpdate(false);
    if (sendEmail) {
      setLoading(true);
      const accessToken = await getToken();
      const response = await fetch(`/api/v1/tasks/${task.id}/request_appt?update_facility=${updateFacility}`,
        {
          method: "POST",
          body: JSON.stringify({ message: { subject: subject, body: convertBodyToHtml(updateMessage) } }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        },
      );
      setLoading(false);
      const status = response.status;
      const json = await response.json();
      if ([201].includes(status)) {
        railsToast({ info: "Email was sent!" }, status);
        task.taskUpdates.push({...json, createdAt: new Date(json.createdAt)});
        if (task.status === TaskStatus.SUGGESTED && newStatus === TaskStatus.IN_PROGRESS) {
          task.status = TaskStatus.IN_PROGRESS;
          task.taskUpdates.push(...json);
          onUpdate(false);
        }
        else
          await updateTaskStatus();
        setUpdateMessage(null);
        setSubject(null);
        setSendEmail(false);
      } else {
        railsToast(json, status);
      }
    } else {
      const accessToken = await getToken();
      if (updateMessage !== '') {
        setLoading(true);
        const response = await fetch(`/api/v1/task_updates`, {
          method: "POST",
          body: JSON.stringify({ value1: updateMessage, taskId: task.id }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setLoading(false);
        const status = response.status;
        const json = await response.json();
        if ([201].includes(status)) {
          railsToast({ info: "Task was successfully updated!" }, status);
          task.taskUpdates.push({...json, value1: updateMessage });
          setUpdateMessage(null);
          onUpdate(false);
          if (newStatus !== task.status)
            await updateTaskStatus();
        } else {
          railsToast(json, status);  
        }
      } else {
        if (newStatus !== task.status)
          await updateTaskStatus();
      }
    }
  };

  const canStartBrowserSession = () => orgRole === 'org:agent' && task.status === TaskStatus.IN_PROGRESS;

  return (
    <>
      <InputGroup>
        <Form.Select className="rounded-bottom-0 w-25" disabled={loading}
          value={sendEmail.toString()} onChange={e => setSendEmail(e.target.value === 'true')}>
          {task.status === TaskStatus.SUGGESTED && task.contacts.length > 0 && orgRole !== 'org:agent'
            && acceptableContactType(task.schedulingMethod) === ContactType.EMAIL ?
          <option value="true">Public reply</option> : <></>}
          <option value="false">Internal notes</option>
        </Form.Select>
        <Stack className="form-control rounded-bottom-0 w-75" direction="horizontal" gap={2}>
          {sendEmail ? <>
            <span>To:</span>
            { acceptableContactType(task.schedulingMethod) === ContactType.EMAIL ?
            <TaskContactsInput task={task} disabled={true} /> : <></> }
          </> : <></>}
        </Stack>
      </InputGroup>
      <div></div>
      {sendEmail ?
      <InputGroup>
        <InputGroup.Text className="rounded-0 border-top-0">Subject: </InputGroup.Text>
        <Form.Control className="rounded-0 border-top-0" type="text" value={subject}
          onChange={e => setSubject(e.target.value)} />
      </InputGroup> : <></> }
      <Form.Control className="rounded-top-0 border-top-0" style={{height: '120px'}} as="textarea"
        disabled={loading} value={updateMessage} onChange={e => setUpdateMessage(e.target.value)} />
      <Stack direction="horizontal" gap={2} className="mt-2">
        { canStartBrowserSession() ? <Button className="ms-auto" variant="outline-primary"
          onClick={startSession}>Start browser session</Button> : <></>}
        <Dropdown className={ canStartBrowserSession() ? '' : 'ms-auto' }>
          <ButtonGroup>
            <Button variant={getStatusClass(newStatus)} onClick={checkContacts} disabled={loading}>
              Update as: <HumanizeType type={newStatus}></HumanizeType></Button>
            <Dropdown.Toggle variant={getStatusClass(newStatus)} disabled={loading}>
            </Dropdown.Toggle>
          </ButtonGroup>
          <Dropdown.Menu>
            {Object.keys(TaskStatus).filter(s => TaskStatus[s] !== TaskStatus.NO_ACTION).map(s => (
              <Dropdown.Item onClick={() => setNewStatus(TaskStatus[s])}>
                <HumanizeType type={TaskStatus[s]}></HumanizeType>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Stack>
      <Modal show={showUpdate} onHide={() => setShowUpdate(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update facility contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Contacts has been changed, Do you want to update the facility contact information?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => onSubmit(false)}>No</Button>
          <Button onClick={() => onSubmit(true)}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateTaskUpdate;