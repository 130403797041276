import {
  IconAlertCircle,
  IconBallBasketball,
  IconBuildingFactory,
  IconBuildingFactory2,
  IconChartHistogram,
  IconCircleCheck,
  IconDashboard,
  IconFileAnalytics,
  IconFileInvoice,
  IconLink,
  IconPlayerRecord,
  IconSettings2,
  IconSubtask,
  IconTruck,
} from "@tabler/icons-react";

import { useAuth } from "@clerk/clerk-react";
import { Nav, Stack } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const SideMenu = () => {
  const { orgRole } = useAuth();

  const isActive = (item) : boolean => {
    return [...item.include || [], item.link].some(link =>
      useLocation().pathname.startsWith(link));
  }

  const data = () => {
    let links = [];

    // links.push({ link: "/", label: "Dashboard", icon: IconDashboard });
    links.push({ link: "/shipments", label: "Shipments", icon: IconTruck, include: ["/tasks"] });
    links.push({ link: "/sops", label: "SOPs Editor", icon: IconSubtask });
    links.push({
      link: "/facilities",
      label: "Facilities",
      icon: IconBuildingFactory2,
    });
    links.push({
      link: "/escalations",
      label: "Escalations",
      icon: IconAlertCircle,
    });
    links.push({
      link: "/organization_settings",
      label: "Billing",
      icon: IconFileInvoice,
    });
    links.push({
      link: "/resources",
      label: "Resources",
      icon: IconLink,
    });
    links.push({
      link: "/organization_settings",
      label: "Reporting",
      icon: IconChartHistogram,
    });
    links.push({
      link: "/organization_settings",
      label: "Settings",
      icon: IconSettings2,
    });

    return links;
  };

  const links = data().filter(item => !item.role || item.role === orgRole)
    .map((item) => (
    <Nav.Link href={item.link} key={item.label}
      className={`text-body w-100 ${isActive(item) ? 'bg-body-secondary' : ''}`}>
      <Stack direction="horizontal" gap={2}>
        <item.icon stroke={1.5} />
        <span>{item.label}</span>
      </Stack>
    </Nav.Link>
  ));

  return (
    <Nav>
      {links}
    </Nav>
  );
}
