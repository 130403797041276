export enum UpdateType {
  MESSAGE = "message",
  EMAIL = "email",
  SCHEDULED_AT = "scheduled_at",
  EARLY_SCHEDULED_AT = "early_scheduled_at",
  STATUS = "status",
  CONTACTS = "contacts",
  SCHEDULING_METHOD = "scheduling_method",
  PLANNED_AT = "planned_at",
  LATE_PLANNED_AT = "late_planned_at",
  SYNCED = "synced",
  CONFIRMATION_NUMBER = "confirmation_number",
  PO_NUMBER = "po_number",
  BROWSER_SESSION = "browser_session"
};

export interface TriggerSource {
  name: string;
  address: string;
}

export interface TaskUpdate {
  value1: string;
  value2: string;
  createdAt?: Date;
  taskId: string;
  updateType: UpdateType;
  createdByName: string;
  triggeredBy: TriggerSource
}
