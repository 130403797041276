import { Shipment } from "@/components/shipments/types";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button, Modal, Spinner, Stack, Table } from "react-bootstrap";
import JsonFormatter from "react-json-formatter";
import TaskStatusPill from "@/components/tasks/show/task-status-pill";
import TaskTypePill from "@/components/tasks/show/task-type-pill";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import TaskContactsInput from "@/components/tasks/show/contacts/task_contacts_input";
import { formatDateTime } from "@/components/utils/date-time-utils";

const ShipmentShow = () => {
  const { getToken } = useAuth();
  const { shipmentId } = useParams();
  const railsToast = useRailsToast();
  const [shipment, setShipment] = useState<Shipment>(null);
  const [lastUpdate, setLastUpdate] = useState<Date>();
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);

  const fetchShipment = async () => {
    const accessToken = await getToken();
    const response = await fetch(
      `/api/v1/shipments/${shipmentId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      }
    );
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      const sh = {...json, tasks: json.tasks.map(t => ({...t,
        createdAt: new Date(t.createdAt), scheduledAt: new Date(t.scheduledAt), 
        taskUpdates: t.taskUpdates.map(tu => ({...tu, createdAt: new Date(tu.createdAt) })) }))};
      setShipment(sh);
      let lastUp = null;
      for (let task of sh.tasks) {
        if (!lastUp || lastUp.getTime() < task.createdAt.getTime())
          lastUp = task.createdAt;
        for (let taskUpdate of task.taskUpdates) {
          if (!lastUp || lastUp.getTime() < taskUpdate.createdAt.getTime())
            lastUp = taskUpdate.createdAt;
        }
      }
      setLastUpdate(lastUp);
    } else {
      railsToast({ info: json.error }, status);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchShipment();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  if (loading) {
    return (
    <div className="text-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    );
  }
  if (shipment)
  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <h1 className="h2">Shipment {shipment.vendorId || shipment.shipmentId}</h1>
        <Button onClick={handleShow} variant="outline-secondary">View data</Button>
      </Stack>
      <div className="border-bottom my-3"></div>
      <Stack direction="horizontal" className="align-items-start">
        <div className="w-50">
          <div>Information</div>
          <div><span className="fw-bold">Customer:</span> {shipment.customer?.name}</div>
        </div>
        <div className="w-50">
          <div>Created at: {formatDateTime(new Date(shipment.createdAt))}</div>
          <div>Last update: {formatDateTime(lastUpdate)}</div>
          <div><span className="fw-bold">Equipment: </span>{shipment.equipmentType}</div>
          <div><span className="fw-bold">Commodity: </span>{shipment.commodity}</div>
          <div><span className="fw-bold">BOL number: </span>{shipment.bolNum}</div>
        </div>
      </Stack>
      {shipment.tasks.map((task, index) => (
        <Stack direction="horizontal" className="mt-3 align-items-start">
          <div className="w-50">
            <Stack direction="horizontal"  className="align-items-start">
              <span className="fw-bold">Stop {index + 1}: </span>
              <div className="ps-1">
                <div>{task.address}</div>
                <div>{task.city}, {task.state}</div>
              </div>
            </Stack>
          </div>
          <div className="w-50">
            <div>
              <span className="fw-bold">Location name: </span>
              <a href={`/facilities/${task.facilityId}`}>{task.locationName}</a>
            </div>
          </div>
        </Stack>
      ))}
      <div className="mt-5">Tasks</div>
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Status</th>
            <th>Scheduling Order</th>
            <th>Contacts</th>
            <th>Last Activity</th>
            <th>Modified By</th>
          </tr>
        </thead>
        <tbody>
          {shipment.tasks.map((task, index) => (
            <tr>
              <td>
                <Stack direction="horizontal" gap={2}>
                  <a href={`/tasks/${task.id}`}>Stop {index + 1}</a>
                  <TaskTypePill taskType={task.taskType} />
                </Stack>
              </td>
              <td><TaskStatusPill status={task.status} /></td>
              <td>{task.seq}</td>
              <td><TaskContactsInput task={task} disabled={true} /></td>
              <td>{formatDateTime(task.taskUpdates[task.taskUpdates.length - 1]?.createdAt)}</td>
              <td>{task.taskUpdates[task.taskUpdates.length - 1]?.createdByName}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Shipment raw data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JsonFormatter json={shipment.data}>
          </JsonFormatter>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ShipmentShow;
