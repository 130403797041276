import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Task, TaskStatus, TaskType } from "../types";
import TaskUpdates from "../../task-updates/list";
import TaskStatusPill from "./task-status-pill";
import CreateTaskUpdate from "../../task-updates/create/index";
import { Button, Form, Modal, Spinner, Stack } from "react-bootstrap";
import TaskTypePill from "./task-type-pill";
import { useAuth } from "@clerk/clerk-react";
import useRailsToast from "@/components/utils/use-rails-toast";
import { listNotes } from "@/components/utils/task-utils";
import SchedulingMethodInput from "@/components/scheduling-methods/input/scheduling_method_input";
import { UpdateType } from "@/components/task-updates/types";
import { SchedulingMethod } from "@/components/scheduling-methods/type";
import TaskContactsInput from "./contacts/task_contacts_input";
import { AppointmentInputType } from "./appointment/type";
import AppointmentInput from "./appointment/appointment_input";
import { isFCFS } from "@/components/utils/sched-method-utils";
import { formatDateTime, timezones } from "@/components/utils/date-time-utils";
import { isCapstone } from "@/components/utils/org-utils";

const TaskShow = () => {
  const { getToken } = useAuth();
  const { taskId } = useParams();
  const railsToast = useRailsToast();
  const [task, setTask] = useState<Task>(null);
  const [confirmNum, setConfirmNum] = useState<string>(null);
  const [showNotes, setShowNotes] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [updateKey, setUpdateKey] = useState<number>(0);

  const fetchTask = async () => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${taskId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      });
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      setTask({...json, createdAt: new Date(json.createdAt),
        scheduledAt: new Date(json.scheduledAt),
        earlyScheduledAt: new Date(json.earlyScheduledAt || json.scheduledAt),
        taskUpdates: json.taskUpdates.map(tu => ({...tu, createdAt: new Date(tu.createdAt) }))});
    } else {
      railsToast({ info: json.error }, status);
    }  
    setLoading(false);
  };

  const syncAppointmentTime = async () => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}/sync_appointment_time`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        }
      });
    const json = await response.json();
    const status = response.status;
    if ([200, 304].includes(status)) {
      railsToast({ info: "Appointment time has been updated on TMS!" }, status);
      task.status = TaskStatus.COMPLETED;
      task.taskUpdates.push(...json);
      taskUpdated();
    } else {
      railsToast({ info: json.error }, status);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchTask();
  }, []);

  useEffect(() => {
    setConfirmNum(task?.confirmNum);
  }, [task]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      updateConfirmNum();
    }
  };

  const updateConfirmNum = () => {
    if (confirmNum != task.confirmNum) {
      updateTask({ confirmNum: confirmNum }, 
        'Appointment confirmation number has been updated!',
        () => task.confirmNum = confirmNum);
    }
  };

  const updateTask = async (taskChanges, message,
    callBack: (result) => void) => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: taskChanges }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      railsToast({ info: message }, status);
      callBack(json);
      task.taskUpdates.push(...json);
      taskUpdated();
    } else {
      railsToast(json, status);
    }
  };

  const taskUpdated = () => setUpdateKey(updateKey+1);

  const taskStatusUpdated = (isCompleted: boolean) => {
    taskUpdated();
    if (isCapstone && isCompleted) {
      syncAppointmentTime();
    }
  }

  const updateSchedMethod = async (task: Task, schedMethod: SchedulingMethod): Promise<boolean> => {
    const accessToken = await getToken();
    const response = await fetch(`/api/v1/tasks/${task.id}`, {
      method: "PUT",
      body: JSON.stringify({ task: { schedulingMethod: schedMethod } }),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const status = response.status;
    const json = await response.json();
    if ([200].includes(status)) {
      railsToast({ info: "Task scheduling method has been updated!" }, status);
      if (json?.length > 0) {
        task.contacts = [];
        for (const update of json) {
          const actions = update.value1.split(' ');
          if (update.updateType === UpdateType.CONTACTS && actions[0] == 'added') {
            task.contacts.push({ taskId: task.id, contact: update.value2,
              contactType: actions[1] })
          }
        }
      }
      task.schedulingMethod = schedMethod;
      taskUpdated();
      return true;
    } else {
      railsToast(json, status);
      return false;
    }
  };

  if (loading) {
    return (
    <div className="text-center">
      <Spinner animation="border" role="status" variant="primary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
    );
  }
  if (task)
  return (
    <>
      <Stack direction="horizontal" className="justify-content-between">
        <Stack direction="horizontal" gap={2}>
          <h1 className="h2">Task: Stop {task.shipment.tasks.findIndex(t => t.id === task.id) + 1}</h1>
          <TaskTypePill taskType={task.taskType} />
        </Stack>
        <Stack direction="horizontal" gap={2}>
          {[TaskStatus.ESCALATED, TaskStatus.AWAITING_SYNC].includes(task.status) ?
            <Button onClick={syncAppointmentTime} variant="outline-secondary">Sync</Button> : <></>}
          <Button onClick={() => setShowNotes(true)} variant="outline-secondary">View notes</Button>
        </Stack>
      </Stack>
      <div className="border-bottom my-3"></div>
      <div>Information</div>
      <Stack direction="horizontal" className="align-items-start">
        <Stack className="w-50">
          <Stack direction="horizontal" gap={2}>
            <span className="fw-bold">Shipment:</span>
            <div><a href={`/shipments/${task.shipmentId}`}>{task.shipment.vendorId || task.shipment.shipmentId}</a></div>
          </Stack>
          <Stack direction="horizontal" gap={2}>
            <span className="fw-bold">Created At:</span>
            <div>{formatDateTime(new Date(task.createdAt))}</div>
          </Stack>
          {task.taskType === TaskType.PRESET ? <></> :
          <Stack direction="horizontal" className="align-items-start" gap={2}>
            <div className="fw-bold">PO Number:</div>
            <div>{task.poNum?.map(po => <div>{po}</div>)}</div>
          </Stack>}
          <Stack direction="horizontal" gap={2}>
            <div className="fw-bold">Confirmation Number:</div>
            <Form.Control type="text" value={confirmNum} className="me-2"
              onChange={e => setConfirmNum(e.target.value)}
              onKeyDown={handleKeyDown} onBlur={updateConfirmNum} style={{maxWidth: '200px'}}/>
          </Stack>
        </Stack>
        <Stack className="w-50">
          <Stack direction="horizontal" className="align-items-start" gap={2}>
            <div className="fw-bold">Location:</div>
            <div><a href={`/facilities/${task.facilityId}`}>{task.locationName}</a></div>
          </Stack>
          <div className="mt-auto"><TaskStatusPill key={updateKey} status={task.status} className="mt-3" /></div>
        </Stack>
      </Stack>
      <div className="mt-3">Activity</div>
      {task.taskType === TaskType.PRESET ? <></> :
      <>
        <div className="mt-2">
          <Stack direction="horizontal" gap={2}>
            <span className="fw-bold">Scheduling Method:</span>
            <SchedulingMethodInput schedMethod={task.schedulingMethod} contacts={task.contacts}
              onUpdate={async (sm) => { return await updateSchedMethod(task, sm); }} />
          </Stack>
        </div>
        <div className="mt-2">
          <Stack direction="horizontal" gap={2}>
            <span className="fw-bold">Contacts:</span>
            <TaskContactsInput key={updateKey} task={task} onUpdate={taskUpdated} />
          </Stack>
        </div>
      </>}
      <div className="mt-2">
        <Stack direction="horizontal" gap={2}>
          <span className="fw-bold">Appointment Time:</span>
          <AppointmentInput task={task} type={AppointmentInputType.DATE} onUpdate={taskUpdated} />
          {!isFCFS(task.schedulingMethod) ? <></> :
          <AppointmentInput task={task} type={AppointmentInputType.EARLY_TIME} onUpdate={taskUpdated} />}
          <AppointmentInput task={task} type={AppointmentInputType.TIME} onUpdate={taskUpdated} />
          <div className="py-2 px-1">{timezones[task.timezone]}</div>
        </Stack>
      </div>
      <div className="mt-5">
        <CreateTaskUpdate key={updateKey} task={task} onUpdate={taskStatusUpdated} />
      </div>
      <div className="mt-2">
        <TaskUpdates key={updateKey} task={task} />
      </div>
      <Modal show={showNotes} onHide={() => setShowNotes(false)} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Location notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            {listNotes(task.locationNotes).map(note => (<li>{note}</li>))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TaskShow;
